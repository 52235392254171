import React from 'react'
import {Review} from "./Review";

import GermanReviews from '../../lang/de/reviews.json';
import DutchReviews from '../../lang/nl/reviews.json';
import FrenchReviews from '../../lang/fr/reviews.json';
import EnglishReviews from '../../lang/en/reviews.json';
import SwissReviews from '../../lang/ch/reviews.json';
import FinishReviews from '../../lang/fi/reviews.json';
import DanishReviews from '../../lang/da/reviews.json';
import NorwegianReviews from '../../lang/nb/reviews.json';
import {Box, Text} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";

export const Reviews = (props) => {
    let reviews = null;

    switch (props.country) {
        case 'nl':
            reviews = DutchReviews;
            break;
        case 'fr':
            reviews = FrenchReviews;
            break;
        case 'en-gb':
        case 'en-ie':
            reviews = EnglishReviews;
            break;
        case 'ch':
            reviews = SwissReviews;
            break;
        case 'fi':
            reviews = FinishReviews;
            break;
        case 'da':
            reviews = DanishReviews;
            break;
        case 'nb':
            reviews = NorwegianReviews;
            break;
        default:
            reviews = GermanReviews;
            break;
    }

    const reviewsContent = reviews.map((review, index) => {
        return (
            <Review key={"review-" + index}
                    author={review.author}
                    date={review.date}
                    score={review.score}
                    review={review.review}/>
        )
    });

    const totalScore = reviews => {
        let totalScore = 0;

        reviews.forEach(review => {
            totalScore += review.score;
        });

        let avgRating = Math.round((totalScore / reviews.length) * 100) / 100;

        return avgRating.toFixed(1);
    }

    return (
        <>
            <Box align="center">
                <Text fontSize="3xl"><FormattedMessage id="complete.product.review.headline"/></Text>
                <Text fontSize="6xl">{totalScore(reviews)}</Text>
            </Box>

            {reviewsContent}
        </>
    )
}
